import React from 'react';

export default class WarningBanner extends React.Component {
  render() {
    return (
      <div className="warning-banner">
        <div className="container tight-container">
          {this.props.children}
        </div>
      </div>
    );
  }
}
