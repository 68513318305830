import { metaTag } from './utilities';

export const defaultScopes = [
  'public_profile',
  'email',
  'pages_show_list',
  'pages_manage_metadata',
  'pages_read_engagement',
  'instagram_basic',
  'instagram_manage_comments',
  'instagram_manage_messages',
  'instagram_manage_insights',
  'business_management'
];

function getAppId() {
  return metaTag('ig_app_id');
}

function getOauthURL(scopes = null) {
  let url;
  if(metaTag("rails_env" ) === "test") {
    url = new URL(window.location.href);
    url.pathname = metaTag('facebook_oauth_url');
  } else {
    url = new URL(metaTag('facebook_oauth_url'));
  }

  if(scopes) {
    url.searchParams.set('scope', scopes.join(','));
  }
  return url.toString();
}

export function getFbLoginForBusinessPageUri(options = {}) {
  const evaluatedLoginScopes = typeof options.scopes == 'function' ? options.scopes() : options.scopes;
  const loginScopes = evaluatedLoginScopes || defaultScopes;

  // TODO: Uncomment this when the FB app is approved with new permissions
  // TODO: leaving this and its TODO for reference - this should be changed in facebook.yml if this is in fact something that needs to be changed
  // return `https://www.facebook.com/dialog/oauth?client_id=${appId}&display=page&extras={"setup":{"channel":"IG_API_ONBOARDING"}}&redirect_uri=${redirectUri}&response_type=token&scope=${loginScopeString}`

  return getOauthURL(loginScopes);
}

export default (options = {}) => {
  const evaluatedLoginScopes = typeof options.scopes == 'function' ? options.scopes() : options.scopes;
  const loginScopes = evaluatedLoginScopes || defaultScopes;
  const initializationCallback = options.onInit || (() => {});
  const initialStatusCallback = options.onInitialStatus || null;
  const statusChangeCallback = options.onStatusChange || (() => {});
  const loginResponseCallback = options.onLoginResponse || (() => {});
  const logoutResponseCallback = options.onLogoutResponse || (() => {});

  window.fbAsyncInit = function () {
    FB.init({
      appId: getAppId(),
      cookie: true,
      xfbml: true,
      version: 'v5.0'
    });

    initializationCallback(FB, options);

    // States: connected, not_authorized (haven't authed into our app before), unknown, other(not logged in to FB)
    if (initialStatusCallback) {
      FB.getLoginStatus(initialStatusCallback);
    }

    window.checkLoginState = function () {
      FB.getLoginStatus(statusChangeCallback);
    };

    window.fbLogin = function () {
      FB.login((response) => {
        loginResponseCallback(response);
        statusChangeCallback(response);
      }, { scope: loginScopes.join(',') });
    };

    window.fbLogout = function () {
      FB.logout(logoutResponseCallback);
    };
  };

  (function (d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) { return; }
    js = d.createElement(s); js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'facebook-jssdk'));
}
