import React from 'react';
import moment from 'moment';
import { nameToAvatarText } from './conversations_helper';
import MediaViewableItem from './media_viewable_item';

export default class Message extends React.Component {
  render() {
    const sender = this.props.message.is_sender;

    let avatarHTML = nameToAvatarText(this.props.displayName);

    if (this.props.profilePictureURL) {
      avatarHTML = (
        <img src={this.props.profilePictureURL} title={this.props.displayName} />
      );
    }

    let messageItem;
    let messageText;
    let mediaToDisplay = null;
    let mediaState = null; // Use null, 'active', 'expired', and 'notSupported'
    let messageToDisplay = null;

    if (!this.props.message.is_supported) {
      mediaState = 'notSupported';
    } else if (this.props.message.media) {
      if (this.props.message.media.is_expired) {
        mediaState = 'expired';
      } else if (!this.props.message.media.url) {
        mediaState = 'notSupported';
      } else {
        mediaState = 'active';
      }
    }

    const messageNotSupportedText = (
      <em>Media not supported yet - possible image, audio clip, or video.</em>
    );

    const messageSender = (
      <div className={`message-sender ${sender === true ? 'hidden' : ''}`}>
        <div className="message-avatar">
          {avatarHTML}
        </div>
      </div>
    )

    const messageTimestamp = (
      <div className='message-timestamp'>
        {moment(this.props.message.sent_at).fromNow()}
      </div>
    );

    if (this.props.message.message && this.props.message.message.length) {
      messageText = this.props.message.message.split("\n").map((line, index) => (
        <p key={index}>{line}</p>
      ));
    }

    if (!mediaState || mediaState == 'notSupported') {
      // media isn't supported or doesn't exist, so we just don't render mediaToDisplay
      mediaToDisplay = null;
      messageToDisplay = <div className="message-snippet">{messageText ? messageText : messageNotSupportedText}{messageTimestamp}</div>;
    } else if (mediaState == 'active') {
      if (messageText) {
        mediaToDisplay = (
          <div className="message-media-reply">
            <div className="message-media"><img src={this.props.message.media.url} /></div>
            <div className="message-snippet">{messageText}{messageTimestamp}</div>
          </div>
        );
      } else {
        mediaToDisplay = (
        <div className="message-media">
          <MediaViewableItem url={this.props.message.media.url} mediaType={this.props.message.media.type} />{messageTimestamp}
        </div>
        );
      }

      messageToDisplay = null;
    } else if (mediaState == 'expired') {
      mediaToDisplay = (<div className="message-media"><img src="/images/media/media-expired-rectangle.png" />{messageTimestamp}</div>);
      messageToDisplay = null;
    }

    messageItem = (
      <div className={`message-item ${sender === true ? 'sender' : 'receiver'}`}>
        {messageSender}
        {messageToDisplay}
        {mediaToDisplay}
      </div>
    );

    return (
      <div key={this.props.message.id}>
        {messageItem}
      </div>
    );
  }
}
