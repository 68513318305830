export const metaTag = (key) => {
  const meta = document.querySelector(`meta.env[name="${key}"]`);
  if (!meta) {
    return '';
  }
  return meta.getAttribute('content');
}

export const shimBugsnag = (bugsnag) => {
  return Object.assign(bugsnag, {
      leaveBreadcrumb: () => {
        console.log.apply(['leaveBreadcrumb'].concat(arguments));
      },
      notify: () => {
        console.log.apply(['notify'].concat(arguments));
      },
    })
}