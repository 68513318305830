import React from 'react';

export default class LoggedOutHome extends React.Component {
  render(){
    let terms = (
      <a href="/terms" target="_blank">Terms</a>
    );

    let privacy = (
      <a href="/privacy" target="_blank">Privacy Policy</a>
    );

    let actionArea = null;

    if (this.props.useFbLoginForBusinessPage) {
      actionArea = (
        <form className="form-inline">
          <input
            type="submit"
            value="Get started for free"
            className="btn btn-primary btn-primary-full"
            onClick={this.props.handleGetStarted}
          />
        </form>
      );
    } else {
      actionArea = (
        <form className="form-inline">
          <div className="form-group">
            <input
              type="text"
              className="form-control mx-sm-2 username-input"
              id="staticUsername2"
              placeholder="Enter your Instagram username"
              onChange={this.props.handleUsernameInputChange}
            />
          </div>
          <input
            type="submit"
            value="Get started for free"
            className="btn btn-primary btn-primary-full"
            disabled={!this.props.usernameInput}
            onClick={this.props.handleGetStarted}
          />
        </form>
      );
    }

    return(
      <div className={`logged-out-container js-logged-out-container ${this.props.showHome ? '' : 'logged-in'}`}>
        <h1>Simple, actionable, Instagram analytics</h1>
        <h2 className="subheadline">Engage with your audience & understand the content they love</h2>
        <div className="instagram-username-form">
          {actionArea}
        </div>
        <p className="subtle click-wrap">By clicking Get started for free, you agree to our {terms} and {privacy}</p>
      </div>
    );
  }
}
