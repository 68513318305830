import React from 'react';
import ModalInvite from './modal_invite';

export default class ConnectModal extends React.Component {
  render (){
    return (
      <div>
        <div className={`custom-modal js-ambassador-modal wizard center ${this.props.showModalInvite ? '' : 'hidden'}`} data-username={this.props.username}>
          <ModalInvite
            handleAcceptInvite={this.props.handleAcceptInvite}
            handleGetStarted={this.props.handleGetStarted}
            handleFbConnect={this.props.handleFbConnect}
            handleManagedMessagesChoice={this.props.handleManagedMessagesChoice}
            handleViewProfile={this.props.handleViewProfile}
            handleUsernameInputChange={this.props.handleUsernameInputChange}
            handleFbLogout={this.props.handleFbLogout}
            handleFbPageCheckboxCheck={this.props.handleFbPageCheckboxCheck}
            page={this.props.page}
            stage={this.props.stage}
            fbStatus={this.props.fbStatus}
            title={this.props.title}
            usernameInput={this.props.usernameInput}
            username={this.props.username}
            description={this.props.description}
            heroImagePath={this.props.heroImagePath}
            userAvatarUrl={this.props.userAvatarUrl}
            isBusinessAccount={this.props.isBusinessAccount}
            brand={this.props.brand}
            brandName={this.props.brandName}
            sourceMsManage={this.props.sourceMsManage}
            sourceMsManageMessages={this.props.sourceMsManageMessages}
            requestMessagePermissions={this.props.requestMessagePermissions}
            fbPageCheckbox={this.props.fbPageCheckbox}
            alertRestartError={this.props.alertRestartError}
            errorCode={this.props.errorCode}
            successCode={this.props.successCode}
            useFbLoginForBusinessPage={this.props.useFbLoginForBusinessPage}
            buildLoginScopes={this.props.buildLoginScopes}
            isPersistingPreferences={this.props.isPersistingPreferences}
          />
          <div className={`footer-callout ${this.props.page == 'root' ? 'hidden' : ''}`}><p>powered by MightyCheck via the Instagram API</p></div>
        </div>
      </div>
    );
  }
}
